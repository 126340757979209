import styled from '@emotion/styled';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { fill_horizontal_all_center, fill_horizontal_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { stockDecimal } from '~/modules/new-version-counsultant/Quote/stockDecimal';
import IntradayTrendChart from '~/modules/trendChart2';
import { store } from '~/pages/heineken_template/_private/store';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
/** 變動的顏色 value:`要顯示的數值` preValue:`被比較的數值` limit:`是否漲跌停` */
/** 可以使用 change與0比較 就可以知道是上漲還是下跌 */
/** 可以使用 high與prevRef比較 就可以知道最高價要給予紅色或綠色 */
export const quoteChangeType = (value, preValue, limit) => {
    if (!limit && value > preValue) {
        return 'rise';
    }
    else if (!limit && value < preValue) {
        return 'fall';
    }
    else if (!limit && value === preValue) {
        return 'flat';
    }
    else if (limit && value > preValue) {
        return 'riseLimit';
    }
    else if (limit && value < preValue) {
        return 'fallLimit';
    }
    else
        return 'none';
};
export const quoteChangeColor = (changeType, riseColor, fallColor, flatColor, otherColor) => {
    if (changeType === 'rise') {
        return riseColor;
    }
    else if (changeType === 'fall') {
        return fallColor;
    }
    else if (changeType === 'flat') {
        return flatColor;
    }
    else
        return otherColor;
};
export const quoteChangeBackGroundColor = (changeType, riseColor, fallColor, otherColor) => {
    if (changeType === 'riseLimit') {
        return riseColor;
    }
    else if (changeType === 'fallLimit') {
        return fallColor;
    }
    else
        return otherColor;
};
export const SymbolQuoteWithChart = memo(function SymbolTrendChart(props_) {
    const theme = useThemeStore(state => state.theme);
    const symbol = props_.symbol;
    const value = useSnapshot(signalrStore2.values.quote)[symbol];
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const close = value?.close ?? 0;
    const open = value?.open ?? 0;
    const high = value?.high ?? 0;
    const low = value?.low ?? 0;
    const riseLimt = value?.limitUpPrice;
    const fallLimt = value?.limitDownPrice;
    const volume = value?.volume ?? 0;
    const prevRef = value?.prevRef ?? 0;
    const quoteChanges = processQuoteToReadable(value);
    const changePercent = quoteChanges.closeChangePercent;
    const change = quoteChanges.closeChange;
    const changeSymbol = change > 0 ? '+' : '';
    const symbolCategory = fr_instrument.getCategoryBySymbol(symbol);
    const limit = close === riseLimt || close === fallLimt;
    const upLimit = high === riseLimt || high === fallLimt;
    const downLimit = low === riseLimt || low === fallLimt;
    const openLimit = open === riseLimt || open === fallLimt;
    const changeColor = quoteChangeType(change, 0, false);
    const closeColor = quoteChangeType(change, 0, limit);
    const openColor = quoteChangeType(open, prevRef, openLimit);
    const highColor = quoteChangeType(high, prevRef, upLimit);
    const lowColor = quoteChangeType(low, prevRef, downLimit);
    return (<classes.container className={theme} onClick={() => store.charting.changeSymbol(symbol)}>
        {/** 商品列嶼產業名稱 */}
        <classes.header className={theme}>
          <div>
            <span>{symbol}</span>
            <span>{dictionary[symbol]}</span>
          </div>
          <span>{symbolCategory}</span>
        </classes.header>

        {/** 商品收盤與漲跌幅資訊 */}
        <classes.body>
          {/** 商品列與產業名稱 */}
          <classes.quote.body>
            <classes.quote.main.body>
              {/** 收盤 */}
              <classes.quote.main.closeItem>
                <classes.quote.main.quoteClose changeType={closeColor} className={theme}>
                  {close?.toFixed(stockDecimal(close))}
                </classes.quote.main.quoteClose>
              </classes.quote.main.closeItem>
              {/** 變動漲幅 */}
              <classes.quote.main.change changeType={changeColor} className={theme}>
                <span>
                  {changeSymbol}
                  {change?.toFixed(stockDecimal(close))}
                </span>
                <span>
                  {changeSymbol}
                  {changePercent}%
                </span>
              </classes.quote.main.change>
            </classes.quote.main.body>
            {/** 其他報價資訊 */}
            <classes.quote.sub.body>
              <classes.quote.sub.item>
                <span>開盤</span>
                <classes.quote.sub.quote changeType={openColor} className={theme}>
                  {open?.toFixed(stockDecimal(open))}
                </classes.quote.sub.quote>
              </classes.quote.sub.item>
              <classes.quote.sub.item>
                <span>參考</span>
                <classes.quote.sub.quote changeType={'flat'} className={theme}>
                  {prevRef?.toFixed(stockDecimal(prevRef))}
                </classes.quote.sub.quote>
              </classes.quote.sub.item>
              <classes.quote.sub.item>
                <span>最高</span>
                <classes.quote.sub.quote changeType={highColor} className={theme}>
                  {high?.toFixed(stockDecimal(high))}
                </classes.quote.sub.quote>
              </classes.quote.sub.item>
              <classes.quote.sub.item>
                <span>最低</span>
                <classes.quote.sub.quote changeType={lowColor} className={theme}>
                  {low?.toFixed(stockDecimal(low))}
                </classes.quote.sub.quote>
              </classes.quote.sub.item>
              <classes.quote.sub.item>
                <span>總量</span>
                <classes.quote.sub.quote changeType={'flat'} className={theme}>
                  {volume}
                </classes.quote.sub.quote>
              </classes.quote.sub.item>
            </classes.quote.sub.body>
          </classes.quote.body>
          {/** 當日走勢圖 */}
          <classes.chart.body>
            <IntradayTrendChart symbol={symbol} ticksSize={12} priceTicksSize={12} priceTicksMargin={-20} ticksHeight={16} transparency={0.4} pricePadding={-2}/>
          </classes.chart.body>
        </classes.body>
      </classes.container>);
});
const classes = {
    container: styled.div `
    display: grid;
    grid-template-rows: 28px 1fr;
    width: 100%;
    height: 172px;
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
    flex-shrink: 0;
    &.dark {
      background-color: #313131;
      border: 1px solid #454545;
      &:hover {
        border: 1px solid #898989;
      }
    }
    &.light {
      background-color: #ececec;
      border: 1px solid #dddddd;
      &:hover {
        border: 1px solid #aaaaaa;
      }
    }
  `,
    header: styled.div `
    ${fill_horizontal_all_center};
    ${jc.spaceBetween};
    font-size: 14px;
    font-weight: bold;
    padding: 0 4px;
    border-radius: 2px;
    &.dark {
      background-color: #232323;
    }
    &.light {
      background-color: #dedede;
    }
  `,
    body: styled.div `
    display: grid;
    grid-template-columns: 42% 58%;
    width: 100%;
    height: 100%;
  `,
    quote: {
        body: styled.div `
      display: grid;
      grid-template-rows: 48% 52%;
      height: 100%;
      width: 100%;
    `,
        main: {
            body: styled.div `
        display: grid;
        grid-template-rows: 60% 40%;
        font-weight: bold;
        height: 100%;
        width: 100%;
        border-bottom: 1px solid #898989;
      `,
            closeItem: styled.div `
        ${fill_horizontal_all_center};
        width: auto;
        height: auto;
        font-size: 18px;
      `,
            quoteClose: styled.span `
        border-radius: 2px;
        padding: 2px;
        &.dark {
          color: ${props => quoteChangeColor(props.changeType, '#ff0000', '#00cc00', '#ffaa00', '#ffffff')};
          background-color: ${props => quoteChangeBackGroundColor(props.changeType, '#ff0000', '#00cc00', 'transparent')};
        }
        &.light {
          color: ${props => quoteChangeColor(props.changeType, '#ff0000', '#00cc00', '#ffaa00', '#ffffff')};
          background-color: ${props => quoteChangeBackGroundColor(props.changeType, '#ff0000', '#00aa00', 'transparent')};
        }
      `,
            change: styled.span `
        ${fill_horizontal_all_center};
        ${jc.spaceEvenly};
        font-size: 14px;
        &.dark {
          color: ${props => quoteChangeColor(props.changeType, '#ff0000', '#00cc00', '#ffaa00', '#ffffff')};
          background-color: ${props => quoteChangeBackGroundColor(props.changeType, '#ff0000', '#00aa00', 'transparent')};
        }
        &.light {
          color: ${props => quoteChangeColor(props.changeType, '#ff0000', '#00cc00', '#ffaa00', '#ffffff')};
          background-color: ${props => quoteChangeBackGroundColor(props.changeType, '#ff0000', '#00aa00', 'transparent')};
        }
      `,
        },
        sub: {
            body: styled.div `
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 1fr 1fr 1fr;
        font-size: 12px;
        letter-spacing: -0.2px;
        font-weight: 500;
      `,
            item: styled.div `
        ${fill_horizontal_cross_center};
        gap: 2px;
      `,
            quote: styled.span `
        border-radius: 2px;
        padding: 0 1px;
        &.dark {
          color: ${props => quoteChangeColor(props.changeType, '#ff0000', '#00cc00', '#ffaa00', '#ffffff')};
          background-color: ${props => quoteChangeBackGroundColor(props.changeType, '#ff0000', '#00aa00', 'transparent')};
        }
        &.light {
          color: ${props => quoteChangeColor(props.changeType, '#ff0000', '#00cc00', '#ffaa00', '#ffffff')};
          background-color: ${props => quoteChangeBackGroundColor(props.changeType, '#ff0000', '#00aa00', 'transparent')};
        }
      `,
        },
    },
    chart: {
        body: styled.div `
      padding: 2px;
    `,
    },
};
